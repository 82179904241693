<template>
  <div
    class="home_page"
    :style="`background-image: url(${require('@/assets/landing/trama.svg')})`"
  >
    <div class="color-azul" v-if="!isCompras"></div>
    <div class="color-amarillo" v-else></div>
    <v-container class="wrap-content-home">
      <h1 class="text-l text-titulo text-center revelar">
        Tu tienda en línea en <br />
        minutos y
        <span style="color: #fff">gratis</span>
      </h1>

      <div class="devices">
        <img
          src="@/assets/landing/phone.png"
          width="300px"
          alt=""
          class="device-phone revelar"
        />
        <div class="device-display revelar">
          <img src="@/assets/tienda.png" width="400px" alt="" srcset="" />
        </div>
        <div class="paymentmetods revelar">
          <img
            src="@/assets/icons/pay-color.svg"
            class="ma-2"
            alt=""
            srcset=""
          />

          <div class="paymentmetods-list">
            <img
              :src="item"
              class="ma-2"
              style="border-radius: 5px"
              v-for="(item, i) in paymentMetss"
              :key="i"
            />
          </div>
        </div>
      </div>

      <!-- <v-col cols="12" sm="12" md="6" lg="6">
          <img
            src="@/assets/landing/iphone_cuadrado.png"
            class="compu-muck"
            alt=""
            srcset=""
          />
        </v-col> -->
    </v-container>
    <v-container>
      <h1 class="text-center">
        Todo en
        <span :style="`color: ${!isCompras ? '#00cdbc' : '#ffc634'}`"> 1</span>
      </h1>
      <v-row class="mt-6">
        <!-- <v-col cols="12" md="12" sm="12" lg="12" class="procesos-title">
          <h1>Procesos</h1>
        </v-col> -->
        <v-col cols="12" md="12" sm="12" lg="12">
          <v-row class="mb-6">
            <v-col
              cols="12"
              md="4"
              sm="6"
              lg="4"
              v-for="(c, index) in Tienda_Pay"
              class="procesos revelar"
              :key="index"
              style="position: relative"
            >
              <div class="cuadro" :style="c.style">
                <img
                  :src="c.icon"
                  :style="`width: ${c.width ? '82%' : '50%'} !important;`"
                  alt=""
                />
              </div>
              <h3>{{ c.title }}</h3>
              <p>{{ c.text }}</p>
              <div class="flecha-next" v-if="index < Tienda_Pay.length - 1">
                <img
                  src="@/assets/icons/arrow-right.svg"
                  width="30px"
                  alt=""
                  srcset=""
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container>
      <v-row>
        <v-col cols="12" md="6" sm="12" lg="6" class="flex-center">
          <div class="ventajas-wrap">
            <h1 class="font-weight-black">Ventajas</h1>

            <v-row class="mt-4 revelar">
              <v-col class="wrap-cuadro-v revelar" v-for="(item, index) in ventajas" :key="index" lg="6" sm="6" cols="6" md="6">
                <div :style="item.style" class="cuadro">
                  <img :src="item.icon" alt="" />
                </div>
                <p class="mt-2">{{ item.title }}</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12" lg="6" class="flex-center">
          <img src="@/assets/landing/2_phone.png" width="100%" alt="" class="two_phone revelar" srcset="" />
        </v-col>
      </v-row>
    </v-container> -->
    <v-container
      fluid
      :class="{ 'metodos-pago': !isCompras, 'metodos-p-compras': isCompras }"
    >
      <h1 class="font-weight-black mt-4 text-center revelar">
        Acepta pagos en línea con <span style="color: #ff5900">OchoPay</span>
      </h1>

      <div class="metodos">
        <div
          class="wrap-cuadro-v revelar"
          v-for="(item, index) in metodos_pay"
          :key="index"
        >
          <a href="https://ocho.life/pay" target="_blanck">
            <div :style="item.style" class="cuadro cuadro-logos">
              <img
                :src="item.icon"
                :style="item.title == 'Ocho pay' ? 'width: 80px' : ''"
              />
            </div>
          </a>
        </div>
      </div>
    </v-container>
    <v-container class="mb-12">
      <v-row class="mt-12">
        <v-col md="6" sm="6" lg="6" cols="12" class="flex-center inner">
          <img :src="imgPanel" width="95%" />
        </v-col>
        <v-col md="6" sm="6" lg="6" cols="12" class="pl-4">
          <h1>Panel Administrativo</h1>
          <p class="text-ordenalo">
            Personaliza tu restaurante con tu logo y tus colores, comparte con
            tus clientes el tracking de cada orden.
          </p>
          <v-row class="wrap-opciones mt-4">
            <v-col
              cols="6"
              md="6"
              sm="6"
              lg="6"
              v-for="(i, index) in options"
              :key="index"
              class="wrap-option revelar"
            >
              <div class="cuadro" :style="i.style">
                <img :src="i.icon" alt="" />
              </div>

              <p>{{ i.title }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-12">
        <v-col cols="12" md="12" sm="12" lg="12" class="procesos-title">
          <h1>Procesos</h1>
        </v-col>
        <v-col cols="12" md="12" sm="12" lg="12">
          <v-row class="mb-12">
            <v-col cols="12" md="4" sm="6" lg="4" v-for="(c, index) in procesos" class="revelar procesos " :key="index"
              style="position: relative">
              <div class="cuadro" :style="c.style">
                <img :src="c.icon" alt="" />
              </div>
              <h3>{{ c.title }}</h3>
              <p>{{ c.text }}</p>
              <div class="flecha-next" v-if="index < procesos.length - 1">
                <img src="@/assets/icons/arrow-right.svg" width="40px" alt="" srcset="" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-container>
    <!-- <v-container>
      <h1 class="text-center">Preguntas frecuentes</h1>
      <v-expansion-panels class="mt-6" active-class="active-panelv-item--active" style="border-radius: 10px;">
        <v-expansion-panel v-for="(item, i) in 5" :key="i" color="primary">
          <v-expansion-panel-header>
            Pregunta {{ i }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container> -->

    <!-- <div class="banner-planes">
      <v-container class="banner">
        <img src="@/assets/landing/phone-2s.png" alt="" srcset="" />
        <div class="text-planes">
          <h1>Tenemos el plan perfecto</h1>
          <v-btn @click="send_planes" style="border-radius: 10px" class="mt-2" elevation="1" width="200px">
            Ver planes
          </v-btn>
        </div>
      </v-container>
    </div> -->
    <v-container class="contact mb-12">
      <h1>Somos el aliado que te apoya en cada paso</h1>
      <p>
        Ya estás más cerca de incrementar tus ventas, completa el formulario y
        nos pondremos en contacto lo antes posible
      </p>
      <v-row>
        <v-col cols="12" md="6" sm="6" lg="6" class="xs-none">
          <img :src="imgComprasForm" width="100%" alt="" srcset="" />
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="6">
          <v-form
            class="pa-4"
            style="background-color: #fff"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              placeholder="Nombre completo"
              filled
              :color="color"
              :rules="requerided"
              v-model="form.name"
              style="border-radius: 10px"
              rounded
            ></v-text-field>
            <v-text-field
              placeholder="Ciudad"
              style="border-radius: 10px"
              filled
              :color="color"
              :rules="requerided"
              v-model="form.city"
              rounded
            ></v-text-field>
            <v-text-field
              placeholder="Nombre de tu negocio"
              filled
              :color="color"
              rounded
              :rules="requerided"
              v-model="form.nameBusiness"
              style="border-radius: 10px"
            ></v-text-field>

            <v-row>
              <v-col cols="2">
                <div class="wrap-flag">
                  <v-menu
                    offset-y
                    transition="slide-x-transition"
                    left
                    class="color-bg"
                    bottom
                    rounded="lg"
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                        <img
                          :src="`${require(`@/Countries/` + Country.flag)}`"
                          alt=""
                          srcset=""
                        />
                        <!-- <p>+{{ Country.callingCodes[0] }}</p> -->
                      </div>
                    </template>
                    <v-list height="250px" class="list">
                      <v-list-item
                        v-for="(item, index) in Countries"
                        :key="index"
                        class="list-item"
                        @click="Country = item"
                      >
                        <div class="img-text">
                          <img
                            :src="`${require(`@/Countries/` + item.flag)}`"
                            alt=""
                            srcset=""
                          />
                          <p class="ml-2">
                            <b>{{ item.name }} </b> +{{ item.callingCodes[0] }}
                          </p>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  placeholder="Teléfono"
                  style="border-radius: 10px"
                  filled
                  :color="color"
                  :prefix="`+${Country.callingCodes[0]}`"
                  @keydown="isNumber($event)"
                  :rules="requerided"
                  v-model="form.phone"
                  rounded
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              placeholder="Correo"
              :rules="emailRules"
              filled
              :color="color"
              v-model="form.email"
              style="border-radius: 10px"
              rounded
            ></v-text-field>
            <v-btn
              :color="isCompras ? '#FFC634':'#00CDBC'"
              block
              :loading="btnLoading"
              style="border-radius: 10px; color: #fff"
              rounded
              @click="validate"
              height="55px"
            >
              Enviar
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <Footer  :isCom="isCompras"/>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
import { db, fb } from "@/firebase";
import phones from "@/components/Landing/phone.vue";
import Footer from "@/components/Landing/footer.vue";
import countries from "@/Countries/countries.json";
import country from "@/Countries/country.json";
import tablee from "@/components/Landing/table-check.vue";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "Landing",
  components: {
    Footer,
    phones,
    tablee,
  },
  data: () => ({
    isCompras: false,
    valid: true,
    form: {
      name: "",
      phone: "",
      city: "",
      email: "",
      nameBusiness: "",
    },
    Countries: countries,
    btnLoading: false,
    Country: country,
    color: "#00cdbc",
    Loding: true,
    procesos: [
      {
        title: "Tienda en línea",
        text: "Compra con en tu menú",
        icon: require("@/assets/icons/store.svg"),
        style: {
          "background-color": "#00CDBC",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
      {
        title: "Pedido a Menu App, Portal Web o Whatsapp",
        icon: require("@/assets/icons/order.svg"),
        text: "Visualiza tus pedidos en cualquiera de estas Plataformas",
        style: {
          "background-color": "#ff5900",
          "box-shadow": "#FF5900 50% 0px 10px 30px",
        },
      },
      {
        title: "Entrega",
        icon: require("@/assets/icons/check.svg"),
        text: "Configura varios tipos de entrega \n• DineIn \n• Delivery \n• Pickup",
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
    ],
    Tienda_Pay: [
      {
        title: "Tienda en línea",
        text: "• Catálogo \n• Métodos de pago \n• Sistema administrativo",
        width: false,
        icon: require("@/assets/icons/store.svg"),
        style: {
          "background-color": "#ffc634",
        },
      },
      {
        title: "Pagos en línea",
        icon: require("@/assets/icons/l.svg"),
        width: true,
        text: "• Tarjeta Crédito / débito\n• Tigo Money \n• Dilo \n• Tengo",
        style: {
          "background-color": "#ff5900",
          "box-shadow": "#FF5900 50% 0px 10px 30px",
        },
      },
      {
        width: false,
        title: "Tipos de entrega",
        icon: require("@/assets/icons/check.svg"),
        text: "• Ordenar en mesa (DineIn) \n• Delivery \n• Pickup \n• Room Service (Hoteles)",
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
    ],
    metodos_pay: [
      {
        title: "Ocho pay",
        icon: require("@/assets/icons/l.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      // {
      //   title: "Efectivo",
      //   icon: require("@/assets/icons/money.svg"),
      //   style: {
      //     "background-color": "#00cdbc",
      //   },
      // },

      // {
      //   title: "Otros",
      //   icon: require("@/assets/icons/edit.svg"),
      //   style: {
      //     "background-color": "#6C99FF",
      //     // "box-shadow": "#6CFFFF 0px 10px 30px",
      //   },
      // },
    ],
    paymentMetss: [
      require("@/assets/paymentLogo/paymentPayment-01.png"),
      // require("@/assets/paymentLogo/paymentPayment-03.png"),
      // require("@/assets/paymentLogo/paymentPayment-05.png"),
      require("@/assets/paymentLogo/paymentPayment-04.png"),
      require("@/assets/paymentLogo/paymentPayment-02.png"),
      require("@/assets/paymentLogo/paymentPayment-06.png"),
    ],
    ventajas: [
      {
        title: "Acceso Web",
        icon: require("@/assets/icons/web.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "#6CFFFF 0px 10px 30px",
        },
      },
      {
        title: "Botón de pago + delivery",
        icon: require("@/assets/icons/delivery-pay.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "rgba(0, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Soporte 24/7",
        icon: require("@/assets/icons/help.svg"),
        style: {
          "background-color": "#ff6c6c",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Tracking de la orden",
        icon: require("@/assets/icons/tracking.svg"),
        style: {
          "background-color": "#1d2d51",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      {
        title: "Tu logo, tus colores",
        icon: require("@/assets/icons/theme.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Administra tus productos",
        icon: require("@/assets/icons/list-product.svg"),
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Pickup, delivery o servir en mesa",
        icon: require("@/assets/icons/dineIn.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "QR - NFC",
        icon: require("@/assets/icons/qr.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
    ],
    options: [
      {
        title: "Administra tus Productos",
        icon: require("@/assets/icons/product.svg"),
        style: {
          "background-color": "#ff5900",
          // "box-shadow": "#6CFFFF 0px 10px 10px",
        },
      },
      {
        title: "Configura tu tema",
        icon: require("@/assets/icons/theme.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Reporte de ventas",
        icon: require("@/assets/icons/grafic.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "#ff5900 0px 10px 30px",
        },
      },
      {
        title: "Descarga tus reportes",
        icon: require("@/assets/icons/report.svg"),
        style: {
          "background-color": "#6FDE7C",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Configura tu menú",
        icon: require("@/assets/icons/custom.svg"),
        style: {
          "background-color": "#fff",
          "box-shadow": "rgba(0, 0, 0, 0.109) 0px 5px 20px",
        },
      },
      {
        title: "Zonas delivery",
        icon: require("@/assets/icons/location.svg"),
        style: {
          "background-color": "#00cdbc",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Genera tu QR",
        icon: require("@/assets/icons/qr.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
      {
        title: "Configuración NFC",
        icon: require("@/assets/icons/NFC.svg"),
        style: {
          "background-color": "#000",
          // "box-shadow": "rgba(255, 0, 0, 0.409) 0px 10px 30px",
        },
      },
    ],
    planes: [
      {
        title: "Básico",
        price: 350,
        time: "/mes",
        dark: false,
        discont: true,
        description:
          "0% comisiones.•Acceso para 1 usuarios.•Personalización de tu restaurante (logo y colores).•Categorías: ilimitados.•Productos: 500.•Carga y actualiza productos vía excel.•Almacena hasta 1GB.•Opción para ordenar en mesa, delivery o pickup.•Recibe órdenes vía Portal Web y Whatsapp.•Portal Web con dashboard y reportería.•Módulo Pagos para cobro en línea con tarjetas.•Módulo Descuentos.•Soporte vía email.",
      },
      {
        title: "Ninja",
        price: 850,
        time: "/mes",
        discont: true,
        dark: true,
        description:
          "0% comisiones.•Acceso para 5 usuarios. •Personalización de tu restaurante (logo y colores).•Categorías: ilimitados.•Productos: 500.•Carga y actualiza productos vía excel.•Almacena hasta 2GB.•Opción para ordenar en mesa, delivery o pickup.•Recibe órdenes vía Portal Web y Whatsapp.•Portal Web con dashboard y reportería.•Módulo Pagos para cobro en línea con tarjetas.•Módulo Descuentos.•Comparte Link de Tracking con tus clientes en órdenes tipo delivery.•Módulo Mensajería para envíos SMS a tu base de clientes.•Módulo Sorteos para giveaways y promos.•Soporte vía Whatsapp.",
      },
    ],
    requerided: [(v) => !!v || "Requerido"],
    emailRules: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Ingresa un correo valido",
    ],
  }),

  computed: {
    planesfilters() {
      let array = this.planes;
      array.forEach((element) => {
        element.description = element.description.split("•");
      });
      return array;
    },
    imgPanel() {
      return this.isCompras
        ? require("@/assets/panelCompras.png")
        : require("@/assets/landing/panel.png");
    },
    imgComprasForm() {
      return this.isCompras
        ? require("@/assets/compras.png")
        : require("@/assets/landing/menu3d-01.jpg");
    },
  },
  methods: {
    ...mapActions(["Alert_", "addData_info"]),
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    send_planes() {
      this.$router.push("/planes");
    },
    cleanForm() {
      this.form = {
        name: "",
        phone: "",
        city: "",
        email: "",
        nameBusiness: "",
      };
    },
    validate() {
      this.$refs.form.validate();
      this.btnLoading = true;
      if (this.$refs.form.validate()) {
        var code = this.Country.callingCodes[0];
        this.form.phone = code + this.form.phone;

        db.collection("menuRequest")
          .add(this.form)
          .then((response) => {
            this.Alert_({
              text: "Tu solicitud fue enviada con éxito, pronto nos pondremos en contacto",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "fa-solid fa-check",
              type: "success",
            });
          });
        this.btnLoading = false;
      } else {
        this.Alert_({
          text: "😬 Completa tu información",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-alert",
          type: "error",
        });
        this.btnLoading = false;
      }
    },
  },
  filters: {
    currency(amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  mounted() {
    var getDomain = document.domain;
    var search = getDomain.search("compra");
    if (search != -1) {
      this.isCompras = true;
    }

    window.scrollTo(0, 0);
    ScrollReveal({
      easing: "ease-in-out",
      reset: true,
      delay: 100,
      distance: "50px",
      origin: "bottom",
    }).reveal(".revelar");
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";

@mixin display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-item--active {
  background-color: #00b5cd !important;
}

.flex-center {
  @include display-center;
}

p {
  color: #6c6c6c;
}

.flecha-next {
  position: absolute;
  right: 30px;
  top: 50%;

  @include responsive(mobile) {
    top: 90%;
    bottom: 0px;
    right: calc(50% - 20px);
    height: 20px;
    transform: rotateZ(90deg);
  }
}

.devices {
  width: 100%;
  // background-color: #00cdbc;

  height: auto;
  display: flex;
  justify-content: center;

  align-items: flex-end;

  @include responsive(mobile) {
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .device-phone {
    width: 15vw;
    transform: translateX(2vw);

    @include responsive(mobile) {
      width: 40vw;

      position: absolute;
      z-index: 10;
      left: 10px;
      bottom: -100%;
    }
  }

  .device-display {
    width: 40vw;
    transform: translateX(2vw);
    filter: drop-shadow(0px 10px 10px #00284e51);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    text-align: center;

    @include responsive(mobile) {
      width: 90vw;
    }

    img {
      width: 100%;
      border-radius: 10px;
    }

    h2 {
      font-size: 2em;
      font-weight: 900;
      margin-top: 20px;
    }
  }

  .paymentmetods {
    width: 20vw !important;
    filter: drop-shadow(0px 10px 20px #00284e51);

    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    transform: translateX(-1vw) translateY(-2vw);
    background-color: #f2f2f2;
    border: #fff solid 1px;
    padding: 10px;
    transition: 0.3s ease-in-out;

    @include responsive(mobile) {
      width: 40vw !important;
      transform: translateX(0vw);
      position: absolute;
      z-index: 10;
      right: 10px;
      bottom: -50%;
    }

    .paymentmetods-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 5px;
      border-top: 1px solid #fff;
    }

    img {
      height: 2.3vw;
      @include responsive(mobile) {
        height: 5vw;
      }
    }
  }
}

.inner {
  perspective: 1000px;

  img {
    transition: 0.3s;
    transform: rotateY(20deg);
    border-radius: 10px;
    box-shadow: #00544d2b 0px 20px 30px;

    &:hover {
      box-shadow: #00544d00 0px 0px 0px;

      transform: rotateY(0deg);
    }
  }
}

.two_phone {
  @include responsive(desktop) {
    width: 80%;
  }

  @include responsive(small) {
    width: 50%;
  }
}

.compu-muck {
  width: 45%;
  bottom: -50px;
  position: absolute;

  @include responsive(mobile) {
    position: relative !important;
    width: 100%;
    bottom: 50px;
  }

  @include responsive(small) {
    position: relative !important;
    width: 70%;

    bottom: 50px;
  }

  @include responsive(desktop) {
    position: relative !important;
    width: 80%;
    bottom: 0px;
  }
}

.home_page {
  width: 100%;
  position: relative;
  height: auto;
  padding-top: 100px;
  background-size: contain;
  background-repeat: repeat-y;

  @include responsive(mobile) {
    background-image: url("../assets/landing/trama-mobile.svg") !important;
  }

  .color-azul {
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-image: radial-gradient(
      circle at 10% 50%,
      #00cdbc,
      #00b5cd
    ) !important;
    // clip-path: ellipse(150% 70% at 0% 25%);
    z-index: 1;
  }
  .color-amarillo {
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    background-image: radial-gradient(
      circle at 10% 50%,
      #ffc634,
      #ffc534d8
    ) !important;
    // clip-path: ellipse(150% 70% at 0% 25%);
    z-index: 1;
  }

  .wrap-content-home {
    z-index: 100;
    height: 100vh;
    position: relative;
    // @include display-center;

    // @include responsive(mobile) {
    //   height: auto;
    //   margin-top: 20px;
    // }

    .text-titulo {
      font-weight: 900;
      font-size: 3em;
      margin-bottom: 10px;

      @include responsive(mobile) {
        font-size: 2em;
        margin-bottom: 40px;
        // height: auto;
        // margin-top: 20px;
        // line-height: 40px;
        // padding-left: 10px;
      }
    }
  }
}

.procesos-title {
  @include responsive(mobile) {
    text-align: center;
  }
}

// ul {
//   padding-left: 5px !important;
//   li {
//     list-style: none;
//   }
// }

.lista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.ventajas-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include responsive(mobile) {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  flex-wrap: wrap;

  .wrap-cuadro-v {
    width: 100%;
    height: 90px;

    @include responsive(mobile) {
      height: auto;
      width: 50% !important;
      text-align: center;
    }

    @include display-center;
    flex-direction: row;
    align-items: center !important;
    justify-content: flex-start;

    @include responsive(mobile) {
      flex-direction: column !important;
      justify-content: center !important;
    }

    .cuadro {
      width: 60px !important;
      height: 60px;
      border-radius: 10px;
      @include display-center;

      img {
        width: 55%;
      }
    }

    p {
      margin-left: 10px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.lista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.btn-saber {
  color: #fff;

  height: 50px !important;
  box-shadow: #1c899f57 0px 0px 0px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: #1c899f57 0px 10px 30px;
  }
}

.metodos {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @include responsive(mobile) {
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .wrap-cuadro-v {
    width: 150px;
    height: 150px;
    @include display-center;
    flex-direction: column;

    .cuadro {
      width: 160px;
      height: 50px;
      border-radius: 10px;
      transition: 0.3s ease-in-out;
      @include display-center;

      &:hover {
        transform: translateY(-5px);
        box-shadow: #ff7d36a1 0px 5px 20px;
      }
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.metodos-pago {
  height: 300px;
  margin-top: 100px;
  width: 100%;
  background-color: #8feee6ba;
  @include display-center;
  flex-direction: column;

  @include responsive(mobile) {
    height: auto;
  }
}
.metodos-p-compras {
  height: 300px;
  margin-top: 100px;
  width: 100%;
  background-color: #ffc534cb;
  @include display-center;
  flex-direction: column;

  @include responsive(mobile) {
    height: auto;
  }
}

.wrap-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3f3f3f;

  @include responsive(mobile) {
    justify-content: center;
    flex-direction: column;
    height: auto;
  }

  .cuadro {
    width: 50px;
    height: 50px;
    border-radius: 10px;

    @include responsive(mobile) {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    @include display-center;

    img {
      width: 25px;
    }
  }

  p {
    text-align: center;
    margin-bottom: 0;
    line-height: 18px;
    margin-left: 10px;
  }
}
@include responsive(mobile) {
  .xs-none {
    display: none;
  }
}
.procesos {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: #3f3f3f;
  padding-right: 30px;

  @include responsive(mobile) {
    width: 70%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
  }

  .cuadro {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    // background-color: #00cdbc;
    @include display-center;

    img {
      width: 50%;
    }
  }

  h3 {
    margin-top: 20px;
  }

  p {
    white-space: pre-wrap !important;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.text-titulo-wrap {
  flex-direction: column !important;
  margin-top: 20px;
  min-width: auto;
  max-width: 500px;
  text-align: center;
}

p {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  margin-bottom: 0;
  margin-top: 10px;
}

.wrap-section-planes {
  @include display-center;
  flex-direction: column;

  .wrap-planes {
    width: 800px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    @include responsive(mobile) {
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: auto;
    }

    .wrap-plan {
      margin-top: 50px;
      width: 350px;
      border-radius: 10px;
      box-shadow: #002e3720 0px 10px 30px;
      padding: 0px 20px 20px 20px;
      background-color: #fff;
      @include display-center;
      flex-direction: column;

      .price-detalle {
        .price {
          text-align: center;
          font-weight: 800;
          font-size: 40px;
          color: #181818;
        }

        .free {
          font-weight: 800;
          font-size: 50px;
          color: #00cdbc;
        }
      }

      .titulo-plan {
        transform: translateY(-50%);
        @include display-center;
        width: calc(100% - 20%);
        border-radius: 10px;
        background-color: #fff;
        height: 50px;
        box-shadow: #1c899f57 0px 10px 30px;

        span {
          font-size: 30px;
          font-weight: 900;
        }
      }

      .dark {
        background-color: #00cdbc !important;
        color: #fff !important;
      }
    }
  }
}

.subrrallado {
  text-decoration-line: line-through;
  text-decoration-color: rgb(247, 102, 102);
}

.contact {
  min-height: 500px;
  height: auto;
  text-align: center;

  h3 {
    max-width: 550px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.227) !important;
}

.wrap-bandera {
  width: 100px;
  height: 20px;
  padding: 5px;
}

.wrap-flag {
  position: relative;

  .flag {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 35px;
      border-radius: 10px;
    }

    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}

.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 30px;
    height: 23px;
    border-radius: 10px;
  }
}

.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 10px;
}

.banner-planes {
  width: 100%;
  height: 300px;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(24, 22, 43);
  margin-top: 200px;

  @include responsive(mobile) {
    height: 200px;
  }

  background-image: radial-gradient(
    circle at 10% 50%,
    #00cdbc78,
    #00b5cd89
  ) !important;

  .banner {
    position: relative;
    height: 300px;
    display: flex;
    justify-items: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 100px;

    @include responsive(mobile) {
      height: 200px;
      padding-right: 0px;
      justify-items: center;
      align-items: center;
    }

    .text-planes {
      width: 50%;
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      @include responsive(mobile) {
        margin-top: 0px;
        width: 100%;
        flex-direction: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-right: 0px;
      }
    }

    img {
      height: 400px;
      position: absolute;
      bottom: 0px;
      left: 100px;

      @include responsive(mobile) {
        height: 300px;
        bottom: 200px;
        left: 0px;
      }
    }
  }
}
.cuadro-logos {
  width: 200px !important;
}
</style>
